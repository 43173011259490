import { useState, Fragment } from "react";
import styled from "styled-components";
import Typist from "react-typist";
import { useKonami } from "react-konami-code";
import KevinOne from "./assets/kevin1.png";
import KevinTwo from "./assets/kevin2.png";
import KevinThree from "./assets/kevin3.png";
import KevinFour from "./assets/kevin4.png";
import KevinFive from "./assets/kevin5.png";
import JttOne from "./assets/jtt1.png";
import JttTwo from "./assets/jtt2.png";
import JttThree from "./assets/jtt3.png";
import JttFour from "./assets/jtt4.png";
import JttFive from "./assets/jtt5.png";
import Roast from "./assets/gayy.png";
import "../node_modules/react-typist/dist/Typist.css";

const Container = styled.div`
  margin: 0;
  padding: 10px;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.isEasterEggShown &&
    `

  filter: blur(10px);
    background-color: tomato;
  `}
`;

const Header = styled.header`
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  border: 3px solid steelblue;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  img,
  & > div {
    width: 50%;
  }

  img {
    margin-left: 15px;
    margin-right: 15px;
    max-height: 375px;
    max-width: 375px;
  }

  .Typist span {
    font-size: 1.3rem;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const MainContainer = styled.div``;

const ImagePair = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 3px solid deepskyblue;
  padding: 20px;
  width: 60%;
  margin: 3px auto;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 400px;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const Image = styled.img`
  display: block;
  max-height: 500px;
  max-width: 100%;
`;

const ImageList = () => {
  const imagePairs = [
    {
      kevin: KevinOne,
      jtt: JttOne,
    },
    {
      kevin: KevinTwo,
      jtt: JttTwo,
    },
    {
      kevin: KevinThree,
      jtt: JttThree,
    },
    {
      kevin: KevinFour,
      jtt: JttFour,
    },
    {
      kevin: KevinFive,
      jtt: JttFive,
    },
  ];

  return (
    <MainContainer>
      {imagePairs.map((pair) => {
        return (
          <ImagePair key={pair.kevin}>
            <ImageContainer>
              <h3>Me</h3>
              <Image src={pair.kevin} alt="kevin" />
            </ImageContainer>
            <ImageContainer>
              <h3>Also Me</h3>
              <Image src={pair.jtt} alt="jtt" />
            </ImageContainer>
          </ImagePair>
        );
      })}
    </MainContainer>
  );
};

const RoastContainer = styled.div`
  position: absolute;
  top: 15%;
  left: calc(50% - 250px);
  width: 500px;
  background-color: white;
  text-align: center;
  z-index: 1000;
  padding: 5px;
  background-color: lightblue;
  border: 3px solid darkslateblue;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    top: 0;
    left: 0;
  }
`;

const SurpriseRoast = () => {
  return (
    <RoastContainer>
      <img width="100%" src={Roast} alt="roasted!" />
    </RoastContainer>
  );
};

const App = () => {
  const [isIntroComplete, setIsIntroComplete] = useState(false);
  const [showEasterEgg, setShowEasterEgg] = useState(false);

  useKonami(() => setShowEasterEgg(true));

  return (
    <Fragment>
      <Container isEasterEggShown={showEasterEgg}>
        <Header>
          <img
            src="https://ca.slack-edge.com/T92KHQ8KZ-UBYFD999U-826157b40f82-512"
            alt="dirtbag"
          />
          <div>
            <h1>Hi! I'm Kevin.</h1>
            <Typist
              onTypingDone={() => {
                setIsIntroComplete(true);
              }}
              cursor={{ hideWhenDone: true }}
            >
              <span>My co-workers </span>
              <Typist.Backspace count={11} delay={200} />
              <span>enemies </span>
              <Typist.Backspace count={8} delay={200} />
              <span>friends call me </span>
              <Typist.Delay ms={1250} />
              <span>Jonathan Taylor Thomas.</span>
            </Typist>
          </div>
        </Header>
        {isIntroComplete && <ImageList />}
      </Container>
      {showEasterEgg && <SurpriseRoast />}
    </Fragment>
  );
};

export default App;
